import React, {Component, Suspense} from 'react';
import {connect} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import Loadable from 'react-loadable';
import LoadingOverlay from 'react-loading-overlay';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import PrivateRoute from '../page/PrivateRoute';
import * as actionTypes from '../store/actions';

const AdminLayout = Loadable({
  loader: () => import('./layout/AdminLayout'),
  loading: Loader
});

class App extends Component {
  render() {
    const menu = routes.map((route, index) => {
      return (route.component) ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={props => (
            <route.component {...props} />
          )} />
      ) : (null);
    });

    return (
      <LoadingOverlay active={this.props.isLoadingActive} spinner>
        <Aux>
          <ScrollToTop>
            <Suspense fallback={<Loader/>}>
              <Switch>
                {menu}
                <PrivateRoute path="/" component={AdminLayout} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Aux>        
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoadingActive: state.isLoadingActive
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadingActive: (isLoadingActive) => dispatch({type: actionTypes.LOADING_ACTIVE, isLoadingActive: isLoadingActive}),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
